import {Injectable} from '@angular/core'

import {NavTab} from '@core/schemes/ui/nav-tab'

@Injectable({
  providedIn: 'root',
})
export class NavTabsService {

  private _navTabs: NavTab[]

  get navTabs(): NavTab[] {
    return this._navTabs
  }

  update(navTabs: NavTab[]): void {
    this._navTabs = navTabs
  }

  clear(): void {
    this._navTabs = null
  }
}
